import { Tag, TagLabel } from '@chakra-ui/react';

const OptionCountLabel = ({ optionCount = 0 }: { optionCount?: number }) => {
  return (
    <>
      {optionCount > 0 && (
        <Tag p={0} bg='primary.600' rounded='full' size='xs' mx={1}>
          <TagLabel
            color='neutral.0'
            w='full'
            textAlign='center'
            fontSize='xs'
            px='0.4em'
            py='0.1em'
          >
            {optionCount}
          </TagLabel>
        </Tag>
      )}
    </>
  );
};

export default OptionCountLabel;
